<template>
  <div class="content">
    <div v-if="USER_INFO.company_bind_status == 2 && companyFileAndInvoiceNum == 4" class="content_area">
      <el-tabs v-model="activeName" type="card" @tab-click="handleTabs" class="tabs">
        <el-tab-pane label="上传委托书" name="first"></el-tab-pane>
        <el-tab-pane label="在线委托" name="second"></el-tab-pane>
      </el-tabs>
      <div v-show="activeName === 'first'">
        <uploadEntrust :userType="1" :histroyInfo="uploadEntrustDetails"></uploadEntrust>
      </div>
      <div v-show="activeName === 'second'">
        <lineEntrust :histroyInfo="histroyInfo"></lineEntrust>
      </div>
      <div class="copyMessage" v-if="activeName === 'second'">
        <span>您可以通过复制历史委托快速录入信息。</span>
        <el-button type="primary" size="mini" style="margin-left: 10px" @click="handleHistroyInfo">复制委托信息</el-button>
      </div>
    </div>
    <yetJoin v-show="USER_INFO.company_bind_status == 0 || USER_INFO.company_bind_status == 3" @bindCompany="bindCompany"></yetJoin>
    <div v-if="USER_INFO.company_bind_status == 1" class="bind_status">认证申请需由公司主账号审核，如有疑问可联系客服人员处理！</div>
    <el-dialog
        title="同步历史委托"
        :visible.sync="histroyInfoDialog"
        width="1000px"
        append-to-body
    >
      <handleHistroyInfo @selectHistroy="selectHistroy" @closeHistroy="closeHistroy"
                         v-if="histroyInfoDialog"></handleHistroyInfo>
    </el-dialog>
    <el-dialog
        title="补充公司信息"
        :visible.sync="addCompanyInfoDialog"
        append-to-body
    >
      <addCompanyInfo v-if="addCompanyInfoDialog" :companyFileAndInvoiceNum="companyFileAndInvoiceNum" @upOK="upOK"
                      @handleClose="handleClose"></addCompanyInfo>
    </el-dialog>
  </div>
</template>

<script>
import yetJoin from "~bac/components/company/basic/yetJoin";
import uploadEntrust from "~ymp/components/bookingSpace/uploadEntrust"
import lineEntrust from "~ymp/components/bookingSpace/lineEntrust"
import handleHistroyInfo from "~ymp/components/bookingSpace/handleHistroyInfo"
import addCompanyInfo from "~ymp/components/bookingSpace/addCompanyInfo"

export default {
  name: "index",
  components: {yetJoin, uploadEntrust, lineEntrust, handleHistroyInfo, addCompanyInfo},
  data() {
    return {
      activeName: 'first',
      histroyInfoDialog: false,
      histroyInfo: {},
      uploadEntrustDetails:{},
      addCompanyInfoDialog: false,
      companyFileAndInvoiceNum: 3,   // 1是没证书，2 是没发票，3是都没有，4是都有
    };
  },
  mounted() {
    
    if (this.$route.query && this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName
    }
    if (!this.IS_LOGIN) {
      return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
        waitTodo: () => {
          this.isLogic()
          if(this.USER_INFO&&this.USER_INFO.user_type==2){
            this.$router.push({
                path:"/",
            })
          }
        },
      })
    } else {
      this.isLogic()
    }
    if(this.$route.query.from=='console'){  //从控制台过来的快速订舱
      if(this.$route.query.type=='1'){
        this.activeName='first'
        this.fonsole_faost_book_first()
      }else{
        this.activeName='second'
        this.fonsole_faost_book_first()
      }
    }
  },
  methods: {
    async fonsole_faost_book_first(){
      let parmas = {
        id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getEntrustDetails", parmas)
      console.log('快速订舱',res);
      if (res.success) {
        if(this.$route.query.type=='1'){
          this.uploadEntrustDetails = res.data
        }else{
          this.histroyInfo=res.data
        }
      }
    },
    handleTabs() {
    },
    handleHistroyInfo() {
      this.histroyInfoDialog = true
    },
    selectHistroy(val) {
      this.getDetail(val.entrust_id)
      this.histroyInfoDialog = false
    },
    closeHistroy() {
      this.histroyInfoDialog = false
    },
    async getDetail(id) {
      let params = {id}
      let res = await this.$store.dispatch("baseConsole/getEntrustDetails", params)
      this.histroyInfo = res.data
    },
    // 判断有没有开票信息和有没有证书
    async getCompanyFileAndInvoice() {
      if (this.USER_INFO.company_bind_status == 0) return
      let params = {
        company_id: this.USER_INFO.company_id
      }
      let res = await this.$store.dispatch("API_bookingSpace/getCompanyFileAndInvoice", params);
      if (res.success) {
        let data = res.data
        if (JSON.stringify(data.invoice_data) == "{}" && JSON.stringify(data.qualification_file) == "{}") {
          this.$message.warning('订舱前请先上传公司证件和开票信息')
          this.companyFileAndInvoiceNum = 3
          this.addCompanyInfoDialog = true
          return
        }
        if (JSON.stringify(data.qualification_file) == "{}") {
          this.$message.warning('订舱前请先上传公司证件')
          this.companyFileAndInvoiceNum = 1
          this.addCompanyInfoDialog = true
        }
        if (JSON.stringify(data.invoice_data) == "{}") {
          this.$message.warning('订舱前请先上传开票信息')
          this.companyFileAndInvoiceNum = 2
          this.addCompanyInfoDialog = true
        }
        if (JSON.stringify(data.invoice_data) != "{}" && JSON.stringify(data.qualification_file) != "{}") {
          this.companyFileAndInvoiceNum = 4
          this.addCompanyInfoDialog = false
        }
      }
    },
    //绑定成功后，再去查看有没有开票信息和证书
    bindCompany() {
      this.isLogic()
    },
    upOK() {
      this.companyFileAndInvoiceNum = 4
      this.addCompanyInfoDialog = false
    },
    handleClose() {
      this.addCompanyInfoDialog = false
    },
    isLogic() {   //判断逻辑,只有绑定公司了，再去判断是否有公司证书和开票信息
      if (this.USER_INFO.company_bind_status == 2) {
        this.getCompanyFileAndInvoice()
      }
    }
  },

}
</script>

<style scoped lang="less">
.content {
  min-height: 82vh;
}

.content_area {
  position: relative;
  padding-bottom: 40px;

  .copyMessage {
    position: absolute;
    top: 34px;
    right: 0;
    display: flex;
    align-items: center;
    color: #606266;
  }
}

.tabs {
  padding-top: 32px;

  /deep/ .is-active {
    color: #91BE42;
  }

  /deep/ .el-tabs__item:hover {
    color: #91BE42;
  }
}
.bind_status {
  text-align: center;
  line-height: 200px;
  font-size: 18px;
}
</style>