<template>
  <div class="uploadEntrustContainer">
    <el-form
        label-width="200px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
    >
      <el-form-item label="海运出口委托书" prop="file1" v-if="userType == 1">
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <div v-for="(item, index) in form.file1" :key="'file1' + index">
              <span class="color298DF8 point" @click="handleFile(item.url)">{{ item.name ? item.name: '未定义'}}</span>
              <span class="chaHao" @click="handleCha(1, index)">x</span>
            </div>
            <span v-if="form.file1.length == 0" class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents1"
              @selectFile="uploadFile($event,1)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 1)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(1)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item prop="file2">
         <span slot="label">
           MSDS
         </span>
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <div v-for="(item, index) in form.file2" :key="'file2' + index">
              <span class="color298DF8 point" @click="handleFile(item.url)">{{ item.name ? item.name: '未定义'}}</span>
              <span class="chaHao" @click="handleCha(2, index)">x</span>
            </div>
            <span v-if="form.file2.length == 0" class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents2"
              @selectFile="uploadFile($event,2)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 2)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(2)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item prop="file3">
         <span slot="label">
           危包证
         </span>
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <div v-for="(item, index) in form.file3" :key="'file3' + index" >
              <span class="color298DF8 point" @click="handleFile(item.url)">{{ item.name ? item.name: '未定义'}}</span>
              <span class="chaHao" @click="handleCha(3, index)">x</span>
            </div>
            <span v-if="form.file3.length == 0" class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents3"
              @selectFile="uploadFile($event,3)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 3)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(3)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item label="CAS NO." prop="cas_no">
        <div class="uploadBox">
          <el-input
              style="width: 200px"
              placeholder="请输入CAS NO."
              type="text"
              v-model="form.cas_no"
              size="mini"
              @blur="casnoBlur"
          ></el-input>
          <div class="CSNO point"  @click="handleHelp"><i class="el-icon-question" style="margin-right: 10px"></i>如何查看CAS NO.信息</div>
        </div>
      </el-form-item>
      <el-form-item label="商检报告" prop="file4">
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <div v-for="(item, index) in form.file4" :key="'file4' + index" >
              <span class="color298DF8 point" @click="handleFile(item.url)">{{ item.name ? item.name: '未定义'}}</span>
              <span class="chaHao" @click="handleCha(4, index)">x</span>
            </div>
            <span v-if="form.file4.length == 0" class="color606266">{{ commodityText }}</span>
          </div>
          <fileUpload
              ref="uploadComponents4"
              @selectFile="uploadFile($event,4)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 4)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(4)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item label="是否大件货" prop="is_big">
        <el-checkbox v-model="form.is_big" :true-label="1" :false-label="0">大件货物</el-checkbox>
        <span style="color: #606266;">（如果货物尺寸长宽高超过1.1M*1.1M*1.1M，请勾选，以便合理安排）</span>
      </el-form-item>
      <el-form-item label="进仓费收取方式" prop="into_storage_fee_way">
        <el-select class="typeSelect" v-model="form.into_storage_fee_way" placeholder="请选择" size="mini">
          <el-option v-for="item in intoStorageFeeWayList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.user_remarks" rows="4" type="textarea" placeholder=""></el-input>
        <div style="color: #999">如资料需延后，请在备注中说明</div>
      </el-form-item>
      <!-- <el-form-item label="报价编号" prop="quotation_no">
        <div class="uploadBox">
          <el-input
              style="width: 200px"
              placeholder="报价编号/询价编号"
              type="text"
              v-model="form.quotation_no"
              size="mini"
          ></el-input>
          <div class="color606266">（报价中的港口信息需与委托书中相同。拼箱海运费用以实时运价为准。）</div>
        </div>
      </el-form-item> -->
    </el-form>
    <el-button
        type="success"
        class="submitBtn"
        size="small"
        @click="handleSubmit"
        v-if="userType == 1"
        :loading="submitLoading">提交委托
    </el-button>
    <el-dialog
        title="资料库"
        :visible.sync="dataBankDialog"
        width="800px"
        append-to-body
    >
      <selectDataBank
          v-if="dataBankDialog"
          :dataBankType="dataBankType"
          @uploadDataBank="uploadDataBank"
          @closeUploadDataBank="closeUploadDataBank"
      ></selectDataBank>
    </el-dialog>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import selectDataBank from "~ymp/components/bookingSpace/uploadEntrustSon/selectDataBank";
import {getFileType} from "~/baseUtils";
import {validateTelephone} from "@/baseUtils/validate";

export default {
  name: "uploadEntrust",
  components: {fileUpload, selectDataBank},
  props: {
    // 1的话，是上传委托书在使用，  2的话，是在线委托使用
    userType: {
      type: String | Number,
      default: 1
    },
    histroyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        cas_no: '',
        is_big: 0,  // 是否是大件货
        into_storage_fee_way: 1,  // 进仓费收取方式
        // quotation_no: '',  // 报价编号
        file1: [],  //海运委托
        file2: [],
        file3: [],
        file4: [],
        user_remarks:""
      },
      commodityText: '输入CAS NO.信息后可知是否需上传',
      rules: {
        file1: {required: true, message: '请上传海运出口委托书'},
        file2: {required: true, message: '请上传MSDS'},
        file3: {required: true, message: '请上传危包证'},
        cas_no: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              this.commodityText = '输入CAS NO.信息后可知是否需上传'
              callback();
            } else {
              if (value) {
                let parmas = {cas_no: value}
                let res = await this.$store.dispatch('API_bookingSpace/getBookingCasInfo', parmas);
                if (res.success && res.data.length > 0) {
                  this.commodityText = '请上传文件'
                  if (this.form.file4.length == 0) {
                    callback(new Error(' '));
                  } else {
                    callback();
                  }
                } else {
                  this.commodityText = '可不提供商检报告'
                  callback();
                }
              }
            }
          },
          trigger: "blur",
        },
        quotation_no: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback();
            } else {
              let parmas = {quotation_no: value}
              let res = await this.$store.dispatch('API_bookingSpace/bookingQuotationNoCheck', parmas);
              if (res.success) {
                callback();
              } else if (res.errorcode == '1025') {
                callback(new Error('报价编号不存在'));
              } else if (res.errorcode == '1026') {
                callback(new Error('报价编号存在但是信息有误'));
              }
            }
          },
          trigger: "blur",
        },
      },
      intoStorageFeeWayList: [
        {
          value: 1,
          label: '司机支付(司机送货时直接付给仓库)'
        },
        {
          value: 2,
          label: '客户支付(客户支付给玉漭)'
        }
      ],
      dataBankDialog: false,  //  资料库选择
      dataBankType: 1,  // 资料库Type
      submitLoading: false,
      files_info: []
    }
  },
  methods: {
    uploadFile(val, index) {
      let obj = {
        name: val[0].name,
        file_type: index,
        size: (val[0].size / 1024).toFixed(2),
        format: getFileType(val[0].name)
      }
      if(this.form[`file${index}`].length < 5) {
        let file = Object.assign({}, obj)
        this.form[`file${index}`].push(file)
        this.$refs[`uploadComponents${index}`].uploadFileToOss()
      } else {
        this.$message.warning('不能超过5个')
      }

    },
    uploadCompleteOSS(val, index) {
      this.form[`file${index}`][this.form[`file${index}`].length -1].url = val.url
    },
    handleFile(url) {
      window.open(url)
    },
    handleCha(index, num) {
      this.form[`file${index}`].splice(num,1)
    },
    handleSubmit() {
      if (this.commodityText == '请上传文件' && this.form.file4.length == 0) {
        return this.$message.warning('请上传商检报告')
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let files_info = this.form.file1.concat([])
          if (this.form.file2.length > 0) {
            files_info = files_info.concat(this.form.file2)
          }
          if (this.form.file3.length > 0) {
            files_info = files_info.concat(this.form.file3)
          }
          if (this.form.file4.length > 0) {
            files_info = files_info.concat(this.form.file4)
          }
          let parmas = {
            entrust_type: 1,
            user_id: this.USER_INFO.id,
            company_id: this.USER_INFO.company_id,
            entrust_kind: 2,
            cas_no: this.form.cas_no,
            is_big: this.form.is_big,
            into_storage_fee_way: this.form.into_storage_fee_way,
            // quotation_no: this.form.quotation_no,
            files_info: JSON.stringify(files_info),
            user_remarks:this.form.user_remarks
          }
          if(this.$route.query.info){
            parmas.freight_id= this.$route.query.info.freight_id
          }
          if (this.$route.query.id) {
            parmas.id = this.$route.query.id
            parmas.status = 0
          }
          if(this.$route.query.from=='console'){
            delete parmas.id
          }

          let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
          this.submitLoading = true
          if (res.success) {
            this.submitLoading = false
            this.$router.push({
              path: '/bookingSpaceTitle',
              query: {
                id: res.data,
                type: 1,
                status: 0
              }
            })
          }
        }
      });
    },
    handleDtatBank(val) {
      this.dataBankType = val
      this.dataBankDialog = true
    },
    // 在资料库选择后
    uploadDataBank(row, type) {
      if(this.form[`file${type}`].length < 5) {
        let num = this.form[`file${type}`].findIndex(item => item.attachment_id == row.attachment_id)
        if(num == -1) {
          this.form[`file${type}`].push(Object.assign({}, row))
          this.dataBankDialog = false
        } else {
          this.$message.warning('不能重复选择资料')
        }
      } else {
        this.$message.warning('不能超过5个')
      }
    },
    //  关闭资料库
    closeUploadDataBank() {
      this.dataBankDialog = false
    },
    // 报价编号失去焦点
    async casnoBlur() {
      if (this.form.cas_no) {
        let parmas = {cas_no: this.form.cas_no}
        let res = await this.$store.dispatch('API_bookingSpace/getBookingCasInfo', parmas);
        if (res.success && res.data.length > 0) {
          this.commodityText = '请上传文件'
        } else {
          this.commodityText = '可不提供商检报告'
        }
      } else {
        this.commodityText = '输入CAS NO.信息后可知是否需上传'
      }
    },
    // 在线提交
    async saveFn() {
      if (this.commodityText == '请上传文件' && this.form.file4 == []) {
        this.$message.warning('请上传商检报告')
        return false
      }
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let files_info = []
            if (this.form.file2.length > 0) {
              files_info = files_info.concat(this.form.file2)
            }
            if (this.form.file3.length > 0) {
              files_info = files_info.concat(this.form.file3)
            }
            if (this.form.file4.length > 0) {
              files_info = files_info.concat(this.form.file4)
            }
            let parmas = {
              entrust_type: 1,
              user_id: this.USER_INFO.id,
              company_id: this.USER_INFO.company_id,
              entrust_kind: 2,
              cas_no: this.form.cas_no,
              is_big: this.form.is_big,
              into_storage_fee_way: this.form.into_storage_fee_way,
              // quotation_no: this.form.quotation_no,
              files_info: JSON.stringify(files_info),
              user_remarks:this.form.user_remarks
            }
            resolve(parmas)
          } else {
            resolve(false)
          }
        });
      })
    },
    initInfo() {
      if (this.$route.query && this.$route.query.detail) {
        let detail = this.$route.query.detail
        this.initInfoFn(detail)
      }
    },
    initInfoFn(detail) {
      this.form.cas_no = detail.cas_no
      this.form.is_big = detail.is_big
      this.form.user_remarks = detail.user_remarks
      this.form.into_storage_fee_way = detail.into_storage_fee_way

      // this.form.quotation_no = detail.quotation_no
      if (detail.attorney.length > 0) {
        let file1 = []
        detail.attorney.forEach(item => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 1
          }
          file1.push(obj)
        })
        this.form.file1 = file1
      }
      if (detail.msds.length > 0) {
        let file2 = []
        detail.msds.forEach(item => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 2
          }
          file2.push(obj)
        })
        this.form.file2 = file2
      }
      if (detail.dangerous_package_license.length > 0) {
        let file3 = []
        detail.dangerous_package_license.forEach(item => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 3
          }
          file3.push(obj)
        })
        this.form.file3 = file3
      }
      if (detail.survey_report.length > 0) {
        let file4 = []
        detail.survey_report.forEach(item => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 4
          }
          file4.push(obj)
        })
        this.form.file4 = file4
      }
      this.casnoBlur()
    },
    handleHelp() {
      window.open('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/casno.jpg')
    }
  },
  mounted() {
    this.initInfo()
  },
  watch: {
    histroyInfo: {
      deep: true,
      handler: function (newV, oldV) {
        this.initInfoFn(newV)
      }
    }
  }
}
</script>

<style scoped lang="less">
.uploadBox {
  display: flex;
  align-items: center;

  .fileName {
    width: 300px;
  }

  .uploadBtn {
    color: #415058;
    width: 80px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    line-height: 32px;
  }

  .uploadBtn2 {
    color: #415058;
    width: 94px;
    height: 32px;
    background: #F0F2F5;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    line-height: 32px;
    text-align: center;
    margin-left: 12px;
    cursor: pointer;
  }
}

.CSNO {
  padding-left: 100px;
  font-weight: 400;
  color: #298DF8;
}

.color298DF8 {
  color: #298DF8;
}

.color606266 {
  color: #606266;
}

.typeSelect {
  width: 250px;
}

/deep/ .el-form-item__label {
  color: #1F292E;
}

.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
}
.chaHao {
  margin-left: 10px;
  cursor: pointer;
}
</style>