<template>
  <div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        基本信息
      </div>
      <div class="content">
        <basicInfo ref="basicInfo" :histroyInfo="histroyInfo"></basicInfo>
      </div>
    </div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        地址信息
      </div>
      <div class="content">
        <uploadAdress ref="uploadAdress" :histroyInfo="histroyInfo"></uploadAdress>
      </div>
    </div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        订舱资料
      </div>
      <div class="content">
        <uploadEntrust ref="uploadEntrust" :userType="2" :histroyInfo="histroyInfo"></uploadEntrust>
      </div>
    </div>
    <div>
      <el-checkbox v-model="isReadMust" class="isReadMust"></el-checkbox>我已仔细阅读并接受
      <el-button type="text" @click="handleReadMust"> 《上海玉漭货运代理协议》</el-button>
    </div>
    <el-button type="success" class="submitBtn" size="small" @click="handleSubmit" :loading="submitLoading">提交委托
    </el-button>
  </div>
</template>

<script>
import uploadEntrust from "./uploadEntrust"
import basicInfo from "./uploadEntrustSon/basicInfo"
import uploadAdress from "./uploadEntrustSon/uploadAdress"

export default {
  name: "lineEntrust",
  components: { uploadEntrust, basicInfo, uploadAdress },
  props: {
    //历史信息
    histroyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      submitLoading: false,
      isReadMust: false
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.isReadMust) return this.$message.warning('请阅读并接受《上海玉漭货运代理协议》')
      let uploadEntrust = await this.$refs.uploadEntrust.saveFn()
      let basicInfo = await this.$refs.basicInfo.saveFn()
      let uploadAdress = await this.$refs.uploadAdress.saveFn()
      if (uploadEntrust && basicInfo && uploadAdress) {
        let parmas = Object.assign(uploadEntrust, basicInfo, uploadAdress)
        parmas.entrust_type = 2
        this.submitLoading = true
        if (this.$route.query && this.$route.query.id) {
          parmas.id = this.$route.query.id
          parmas.status = 0
        }

        if(this.$route.query.from=='console'){
          delete parmas.id
        }
         if(this.$route.query.info){
            parmas.freight_id= JSON.parse(this.$route.query.info).freight_id
          }
        let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
        if (res.success) {
          this.submitLoading = false
          this.$router.push({
            path: '/bookingSpaceTitle',
            query: {
              id: res.data,
              type: 2,
              status: 0
            }
          })
        }
      }
    },
    handleReadMust() {
      window.open('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/%E4%B9%B0%E5%8D%95%E7%89%88%E8%B4%A7%E8%BF%90%E4%BB%A3%E7%90%86%E5%8D%8F%E8%AE%AE%E4%B9%A6-%E7%8E%89%E6%BC%AD%E7%A7%91%E6%8A%80.pdf')
    }
  }
}
</script>

<style scoped lang="less">
.tableContainer {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  margin-top: 20px;

  .content {
    padding: 32px;
  }
}

.titleBox {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px #C0C4CC solid;
  padding-left: 32px;

  .titleLine {
    width: 2px;
    height: 16px;
    background: #22D00E;
    position: absolute;
    left: 0;
    top: 19px;
  }
}

.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
  margin-top: 15px;
}
.isReadMust {
  margin-top: 10px;
  margin-right: 10px;
}
</style>