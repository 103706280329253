<template>
  <div>
    <div class="search">
      <div class="searchInputs">
        <el-input style="width: 200px;margin-right: 10px" v-model="number" size="small" placeholder="委托编号/业务编号"></el-input>
        <portSelect
            v-model="dest_port"
            placeTitle="请输入目的港"
            clearable
        />
      </div>

      <el-button size="small" class="submitBtn" type="success" @click="getDataList">查询</el-button>
    </div>
    <el-table
        :data="dataList"
        border
        highlight-current-row
        @current-change="handleCurrentChange"
        size="mini"
        style="width: 100%;height: 500px"
        :loading="loading"
    >
      <el-table-column prop="name" label="委托编号" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.entrust_no | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="format" label="目的港" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          [{{ scope.row.dest_port_code | textFormat }}]
          {{ scope.row.dest_port_name_en | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="size" label="是否整箱" :show-overflow-tooltip="true"  width="80" >
        <template slot-scope="scope">
          {{ scope.row.entrust_kind == 1 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="件/毛/体" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div v-if="scope.row.amount">
            {{ scope.row.amount | textFormat }}PCS
            /
            {{ scope.row.gross_weight | textFormat }}KGS
            /
            {{ scope.row.volume | textFormat }}CBM
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="cargo_name" label="品名" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.cargo_name | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="发货人" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div v-if="scope.row.shipper && scope.row.shipper.name">{{ scope.row.shipper.name }}</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="收货人" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div v-if="scope.row.consignee && scope.row.consignee.name">{{ scope.row.consignee.name }}</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="success" class="submitBtn" size="small" @click="handleSubmit">确定</el-button>
    </div>
  </div>

</template>

<script>
import portSelect from '~ymp/components/common/portSelect'

export default {
  name: "handleHistroyInfo.vue",
  components: {
    portSelect
  },
  data() {
    return {
      dataList: [],
      nopage: 1,
      currentRow: null,
      loading: false,
      number: '',
      dest_port: ''
    }
  },
  filters: {
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      let parmas = {
        user_id: this.USER_INFO.id,
        number: this.number,
        dest_port: this.dest_port,
        nopage: 1
      }
      this.loading = true
      let res = await this.$store.dispatch("API_bookingSpace/getBookingEntrustHistoryList", parmas);
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleCurrentChange(row) {
      this.currentRow = Object.assign({}, row)
    },
    handleSubmit() {
      if (this.currentRow !== null) {
        this.$emit('selectHistroy', this.currentRow)
      } else {
        this.$message.warning('请选择地址')
      }
    },
    handleClose() {
      this.$emit('closeHistroy')
    }
  }
}
</script>

<style scoped lang="less">
.search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.searchInputs {
  display: flex;
}
/deep/ .el-table__body tr.current-row > td {
  background-color: rgb(145, 190, 66) !important;
  div {
    color: #fff;
  }
}
.btns {
  margin-top: 20px;
  text-align: right;
}
.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
}
</style>